import { Col, Row, Spin, Switch, Menu } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { LayoutTabMember } from 'components/member.component';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import { specialProviderName } from 'utils/common';
import useSiteById from 'hooks/site-id.hook';

// 會員列表: 遊戲商資料

const PageMain: React.FC = () => {
  const { id, platformId, account, agId } = useParams();
  const { data: site } = useSiteById(platformId);
  const [data, setData] = useState<ProviderInfo[]>([]);

  const { data: MemberProviderList, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/provider/settings`,
    allow: !!id && !!platformId
  });

  useEffect(() => {
    if (MemberProviderList) {
      setData(MemberProviderList?.Data);
    }
  }, [MemberProviderList])


  const [gameMenuItems, setGameMenuItems] = useState([]);

  useEffect(() => {
    if (site && MemberProviderList?.Data) {
      const MenuItemObject: any = {};

      MemberProviderList.Data.forEach((item: any) => {
        const label = specialProviderName(item.ProviderName, site.SiteName, item.ProviderCode);
        if (!MenuItemObject[label]) {
          MenuItemObject[label] = {
            label: (
              <span className="font-w-md">{label}</span>
            ),
            children: [
              {
                key: `${item.ProviderCode}$$${item.CategoryCode}`,
                label: (
                  <Row align="middle" justify="space-between" gutter={[12, 12]} className="w-full" wrap={false} >
                    <Col>
                      <span className="color-07">{i18n.t("gameCategory")} : </span>
                      <span className="font-w-md">{i18n.t(item?.CategoryCode)}</span>
                    </Col>
                    <Col>
                      <span className="color-07">{`${i18n.t("game")}${i18n.t("account")}`} : </span>
                      <span className="font-w-md">{i18n.t(item?.GameAccount)}</span>
                    </Col>
                    <Col>
                      <Switch checked={item.Status} disabled/>
                    </Col>
                  </Row>
                ),
              }
            ]
          }
        } else {
          MenuItemObject[label].children.push({
            key: `${item.ProviderCode}$$${item.CategoryCode}`,
            label: (
              <Row align="middle" justify="space-between" gutter={[12, 12]} className="w-full" wrap={false}>
                <Col >
                  <span className="color-07">{i18n.t("gameCategory")} : </span>
                  <span className="font-w-md">{i18n.t(item?.CategoryCode)}</span>
                </Col>
                <Col>
                  <span className="color-07">{`${i18n.t("game")}${i18n.t("account")}`} : </span>
                  <span className="font-w-md">{i18n.t(item?.GameAccount)}</span>
                </Col>
                <Col>
                  <Switch checked={item.Status} disabled/>
                </Col>
              </Row>
            ),
          })
        }
      })
      setGameMenuItems(Object.values(MenuItemObject));
    }
  }, [MemberProviderList])

  return (
    <div id="gameProviderData">
      <LayoutNav />
      <Content>
        <LayoutTabMember activeKey="2" id={id} platformId={platformId} account={account} agId={agId}/>
        <Row>
          {/* 遊戲錢包資料 */}
          <Col span={24} className="mt-1">
            <Spin spinning={isValidating}>
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <Menu
                    mode="inline"
                    items={gameMenuItems?.filter((_, i) => i % 3 === 0)}
                  />
                </Col>
                <Col span={8}>
                  <Menu
                    mode="inline"
                    items={gameMenuItems?.filter((_, i) => i % 3 === 1)}
                  />
                </Col>
                <Col span={8}>
                  <Menu
                    mode="inline"
                    items={gameMenuItems?.filter((_, i) => i % 3 === 2)}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;