import { Button, Col, Form, Input, message, Row, Switch, Table, Tooltip } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupDeleteAnnouncement } from 'components/advanced.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import { RESPONSE_CODE_CREATE_ANNOUNCEMENT } from 'constants/response';
import useAccount from "hooks/account.hook";
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $api, $get } from "services";
import { timeS2L } from "utils/common";
import { SiteCodeAndName } from "components/site.component";

// 系統公告

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [id, setId] = useState<number | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [params, setParams] = useState({
  });

  const { data, isValidating, mutate } = $get({
    url: 'admin/system-announcement/announcements',
    params: {
      ...params,
      page: page[0],
      rows: page[1]
    }
  })

  const search = (formData: any) => {
    setParams({
      ...params,
      title: formData.title,
      siteIds: formData.siteId ? (formData.siteId).join(',') : ''
    })
    mutate();
  }

  const handleUpdateStatus = (record: any, state: boolean) => {
    $api('PATCH', {
      url: `admin/system-announcement/announcements/${record.id}/status`,
      send: {
        isEnabled: !record.isEnabled,
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_CREATE_ANNOUNCEMENT
    })
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const handleDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search}>
          <Row gutter={[12, 12]}>
            <Col>
              <Form.Item className="w-12" name="siteId">
                <SiteCodeAndName name="siteId" form={form} apiUrl={'admin/system-announcement/sites'} 
                  mode="multiple" className="w-12" />
              </Form.Item>
            </Col>
            <Col >
              <Row align="top" gutter={10}>
                <Col>
                  <Form.Item name="title">
                    <Input className="w-12" placeholder={`${i18n.t('title')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" loading={isValidating}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {$p('systemAnnouncement.addSystemAnnouncement') && 
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Button type="primary" onClick={() => navigate('/advanced/announcement/system/add')}>
                    {i18n.t('add')}
                  </Button>
                </Col>
              </Row>
            </Col>}
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                tableLayout="auto"
                dataSource={
                  data?.Data.map((item: any) => ({ ...item, key: item.id }))
                }
                columns={[
                  {
                    title: i18n.t('sort'),
                    dataIndex: 'sequence',
                    align: 'center',
                    width: 60
                  },
                  {
                    title: i18n.t('title'),
                    dataIndex: 'title',
                    width: 260,
                    render: (_, { title }: any) => (
                      <Tooltip title={title}>
                        <div style={{
                          whiteSpace: 'nowrap', maxWidth: 300,
                          overflow: 'hidden', textOverflow: 'ellipsis'
                        }}>{title}</div>
                      </Tooltip>
                    )
                  },
                  {
                    title: i18n.t('internalContent'),
                    dataIndex: 'contents',
                    width: 320,
                    ellipsis: true,
                    render: (val) => (
                      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: val }} />}>
                        <div style={{
                          whiteSpace: 'nowrap', maxWidth: 600,
                          overflow: 'hidden', textOverflow: 'ellipsis'
                        }}>{val?.replace(/<\/?[^>]+(>|$)/g, "")}</div>
                      </Tooltip>
                    )
                  },
                  {
                    title: `${i18n.t('platformCode')}-${i18n.t('platformName')}`,
                    dataIndex: 'sites',
                    width: 240,
                    ellipsis: true,
                    render: (val) => val.map((item: any) => `${item.code}-${item.name}`).join(' / ')
                  },
                  {
                    title: i18n.t('publicationTime'),
                    dataIndex: 'beginTime',
                    width: 150,
                    render: (val) => timeS2L(val)
                  },
                  {
                    title: i18n.t('endTime'),
                    dataIndex: 'endTime',
                    width: 150,
                    render: (val) => timeS2L(val)
                  },
                  {
                    dataIndex: 'operateUser',
                    title: i18n.t('updater'),
                    width: 150,
                    render: (val) => val?.account || 'System'
                  },
                  {
                    title: i18n.t('status'),
                    dataIndex: 'isEnabled',
                    width: 80,
                    render: (val, record) => (
                      <Switch
                        disabled={!$p('systemAnnouncement.modifySystemAnnouncement')}
                        loading={isValidating}
                        checked={val}
                        onClick={v => handleUpdateStatus(record, v)}
                      />
                    )
                  },
                  {
                    title: i18n.t('operation'),
                    width: 150,
                    render: (_, record: any) => (
                      <>
                        {$p('systemAnnouncement.modifySystemAnnouncement') && 
                        <Button className="size-12" type="link" 
                          onClick={() => navigate(`/advanced/announcement/system/edit/${record.id}`)}>
                          {i18n.t('edit')}
                        </Button>}
                        {$p('systemAnnouncement.removeSystemAnnouncement') && 
                        <Button className="size-12" type="link" 
                          onClick={() => handleDelete(record.id)}>
                          {i18n.t('delete')}
                        </Button>}
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={data?.Total} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>

      <PopupDeleteAnnouncement isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} id={id} mutate={mutate} />
    </div>
  );
};

export default PageMain;