import { Button, Col, Form, Input, Row, Select, Spin, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { PopupBatchUpdate, ViewGameList } from 'components/advanced.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import { GameCategorySelect } from 'components/member.component';
import useAccount from 'hooks/account.hook';
import useSiteById from 'hooks/site-id.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $api, $get } from "services";
import { selectFuzzyFilter, specialProviderName } from 'utils/common';
import { SiteCodeAndName } from "components/site.component";

// 遊戲管理

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [isRefresh, setIsRefresh] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState({});
  const [selectProvider, setSelectProvider] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [batchUpdateValue, setBatchUpdateValue] = useState<number | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [siteId, setSiteId] = useState<any>("");
  const [triggerApi, setTriggerApi] = useState(false);

  // 站台資訊
  const { data: $s } = useSiteById(siteId);

  // 取得遊戲商代號列表
  const { data: provider } = $get({
    url: `admin/game/sites/${siteId}/provider/codes`,
    allow: !!siteId
  });

  // 取得遊戲列表
  const { data: gameList, isValidating, mutate } = $get({
    url: 'admin/game/list',
    params: {
      ...params,
      page: page[0],
      rows: page[1]
    },
    allow: !!siteId && triggerApi
  })

  useEffect(() => {
    setSelectProvider('');
  }, []);

  // useEffect(() => {
  //   if (provider) {
  //     form.setFieldsValue({
  //       site: null,
  //       gameProvider: null,
  //       gameCategory: null,
  //       gameCode: null,
  //       gameName: null
  //     })
  //   }
  // }, [provider])

  const onFinish = (formData: any) => {
    setParams({
      siteId: formData.siteId,
      ProviderCode: formData.gameProvider,
      CategoryCode: formData.gameCategory,
      Code: formData.gameCode,
    });
    setPage([1, page[1]]);
    setTriggerApi(true);
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setSiteId(null);
    setSelectProvider('');
    setBatchUpdateValue(null);
    setSelectedRowKeys([]);
  }
  // 刷新遊戲
  const onRefresh = () => {
    $api('PUT', {
      url: `admin/game/refresh/${selectProvider}`,
      send: {
        siteId: siteId
      },
      queryHeader: true,
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
      },
    }, setIsRefresh)
  }

  const onBatchUpdate = (select: number) => {
    setBatchUpdateValue(select);
    setIsOpenModal(!isOpenModal);
  }

  const siteCodeChange = (id: number) => {
    onClear();
    form.setFieldsValue({ siteId: id });
    setTimeout(() => {
      setSiteId(id);
      form.submit();
    });
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} >
          <Row align="top" gutter={[12, 12]}>
            <Col>
              <Form.Item className="w-12" name="siteId" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}${i18n.t('platform')}` }]}>
                <SiteCodeAndName name="siteId" form={form} apiUrl={'admin/game/sites'} 
                  handleChangeFunction={siteCodeChange} className="w-12" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="gameProvider">
                <Select
                  disabled={!siteId}
                  showSearch
                  filterOption={selectFuzzyFilter}
                  placeholder={i18n.t('gameProvider')}
                  onChange={setSelectProvider}
                  options={provider && [
                    { value: '', label: i18n.t('ALL') },
                    ...provider?.Data?.map((item: any) => ({
                      value: item.providerCode,
                      label: specialProviderName(item.providerName, $s.SiteName, item.providerCode)
                    }))]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="gameCategory">
                <GameCategorySelect name="gameCategory" form={form} code={true} disabled={!siteId} siteId={siteId}/>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="gameCode">
                <Input placeholder={`${i18n.t('gameCode')}`} disabled={!siteId} />
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" loading={isValidating}>
                {i18n.t('search')}
              </Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>

            {/* 第二排 */}
            <Col span={24}>
              <Row align="top" gutter={[12, 12]}>
                <Col>
                  <Button type="primary" onClick={onRefresh} loading={isRefresh}
                    disabled={!selectProvider}>
                    {i18n.t('refresh')}{selectProvider}{i18n.t('game')}
                  </Button>
                </Col>
                <Col>
                  <Select
                    className="w-8"
                    placeholder={i18n.t('batchUpdateStatus')}
                    onChange={onBatchUpdate}
                    value={batchUpdateValue}
                    disabled={selectedRowKeys.length === 0 || !$p('game.modifyCCGame')}
                    options={[
                      { label: i18n.t('ON'), value: true },
                      { label: i18n.t('OFF'), value: false },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
                <ViewGameList data={gameList?.Data} mutate={mutate} selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys} siteId={siteId} GameUrl={gameList?.Headers.get('Gameiconurl')} isValidating={isValidating} />
                <LayoutPagination total={gameList?.Total} page={page} setPage={setPage} />
            </Col>
          </Row>
        </Form>
      </Content>

      <PopupBatchUpdate isOpen={isOpenModal} close={() => setIsOpenModal(false)} selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys} batchUpdateValue={batchUpdateValue}
        setBatchUpdateValue={setBatchUpdateValue} mutate={mutate}
        siteId={siteId} />
    </div>
  );
};

export default PageMain;