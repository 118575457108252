import { Button, Col, Image, Row, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlatformLayout, PopupAddLicense, PopupDeleteLicense, PopupEditLicense } from 'components/advanced.component';
import { LayoutNav } from 'components/layout.component';
import { ENABLE } from 'constants/state';
import useAccount from 'hooks/account.hook';
import { RootType } from 'hooks/store.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { $get } from 'services';
import mockLicense from 'services/license.service';

// 站台設置: 上傳牌照

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [id, setId] = useState<number | null>(null);
  const [record, setRecord] = useState<any>();
  const [siteId, setSiteId] = useState(useSelector((state: RootType) => state.Select.siteId) || null);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const { data, mutate, isValidating } = $get({ 
    url: `admin/license/sites/${siteId}/licenses`, 
    allow: !!siteId,
    mock: mockLicense['sites/1/licenses']
  });

  const statusColor = (status: number) => {
    switch (status) {
      case ENABLE.enabled:
        return 'color-pass';
      case ENABLE.disabled:
        return 'color-reject';
    };
  };

  const onEdit = (record: any) => {
    setRecord(record);
    setIsOpenEdit(true);
  };

  const onDelete = (id: number) => {
    setId(id);
    setIsOpenDelete(true);
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <PlatformLayout tabKey={'6'} setSiteIdOutside={setSiteId} />

        {!!siteId &&
        <Row align="middle" gutter={[12, 12]}>
          {$p('license.createLicense') &&
          <Col span={24}>
            <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>
          </Col>}
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              tableLayout="auto"
              dataSource={data?.Data.map((item: any) => ({ key: item.Id, ...item }))}
              columns={[
                {
                  dataIndex: 'Position',
                  title: i18n.t('sort'),
                  align: 'center',
                  width: 80
                },
                {
                  dataIndex: 'Title',
                  title: i18n.t('title'),
                  width: 300,
                  render: (val) => (
                    <div style={{
                      whiteSpace: 'nowrap', overflow: 'hidden',
                      textOverflow: 'ellipsis', maxWidth: 300
                    }}>
                      {val}
                    </div>
                  )
                },
                {
                  dataIndex: 'photo_1',
                  title: i18n.t('image'),
                  width: 300,
                  render: (val) => <Image src={val} style={{ maxWidth: 140, maxHeight: 50 }} />
                },
                {
                  dataIndex: 'LinkURL',
                  title: i18n.t('linkURL'),
                  width: 300,
                  render: (val) => val || '-'
                },
                {
                  dataIndex: 'Status',
                  title: i18n.t('status'),
                  width: 80,
                  render: (val) => <div className={statusColor(val)}>{i18n.t(ENABLE[val])}</div>
                },
                {
                  title: i18n.t('operation'),
                  width: 80,
                  render: (_, record: any) => (
                    <>
                      {$p('license.updateLicense') &&
                      <Button type="link" onClick={() => onEdit(record)}>{i18n.t('edit')}</Button>}
                      {$p('license.removeLicense') &&
                      <Button type="link" onClick={() => onDelete(record.key)}>{i18n.t('delete')}</Button>}
                    </>
                  )
                }
              ]}
              pagination={false}
            />
          </Col>
        </Row>}
      </Content>

      <PopupAddLicense isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} mutate={mutate} />
      <PopupEditLicense isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} data={record} mutate={mutate} />
      <PopupDeleteLicense isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} id={id} mutate={mutate} />
    </div>
  );
};

export default PageMain;